import React, { Component } from "react";
import { getBaseURL } from "../helpers/HostURLHelper.jsx";
import $ from "jquery";
import GridContainer from "../../exports/components/Grid/GridContainer";
import GridItem from "../../exports/components/Grid/GridItem";
import Button from "../../exports/components/CustomButtons/Button.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import userLandingPageStyle from "../../user_components/styles/UserLandingPageStyle.jsx";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { FilePond } from "react-filepond";

import RegisterNewUser from "./RegisterNewUser";

import Link from "@material-ui/core/Link";

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: "8px",
      width: "40%",
    },
  },

  input: {
    width: "83.5%",
    [theme.breakpoints.down("md")]: {
      width: "96%",
    },
  },
  addUserBtn: {
    width: "84%",
    textTransform: "none",
    backgroundColor: "#3f51b5",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#3f51b5",
    },
    [theme.breakpoints.down("md")]: {
      width: "96%",
    },
  },
  tabPanel: {
    backgroundColor: "#ffffff",
    color: "black",
    marginTop: "10px",
  },
  ...userLandingPageStyle,
});

class CreateNewUser extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      value: 0,
      userId: 0,
      name: "",
      branches: [],
      programs: [],
      sections: [],
      userGroups: [],
      userRoles: [],
      files: "",
      fileExtension: "",
      uploadStatus: "",
      errorMessage: "",
    };
  }

  handleTabChange = (e, newValue) => {
    this.setState({ value: newValue });
  };

  fetchData = () => {
    $.ajax({
      type: "GET",
      url: getBaseURL() + "/branches.json",
    }).done((data) => {
      var branches = [];
      branches = data["global_branches"];
      this.setState({ branches });
    });

    $.ajax({
      type: "GET",
      url: getBaseURL() + "/global_programs.json",
    }).done((data) => {
      var programs = [];
      programs = data["programs"];
      this.setState({ programs });
    });

    $.ajax({
      type: "GET",
      url: getBaseURL() + "/sections.json",
    }).done((data) => {
      var sections = [];
      sections = data["global_sections"];
      this.setState({ sections });
    });

    /*	$.ajax({
			type: 'GET',
			url: getBaseURL() + '/roles.json'
		}).done((data) => {
			var userRoles=[];
			userRoles=data["roles"];
			this.setState({userRoles});
		});*/

    $.ajax({
      type: "GET",
      url: getBaseURL() + "/search-user-groups.json",
    }).done((data) => {
      var userGroups = [];
      userGroups = data;
      this.setState({ userGroups });
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  setFiles = (fileItems) => {
    // Set currently active file objects to this.state
    this.setState({
      files: fileItems.map((fileItem) => fileItem.file),
      fileExtension: fileItems.map((fileItem) => fileItem.fileExtension),
    });
  };

  uploadFileData = (e) => {
    e.preventDefault();
    var files = this.state.files;
    if (files.length < 1) {
      alert("Please select the file and upload");
      return;
    }

    var fileExtension = this.state.fileExtension;
    if (fileExtension.length > 0 && fileExtension[0] != "csv") {
      alert("Only .csv format file is supported.");
      return;
    }

    var branchNames = this.state.branches.map((obj) => obj.name);
    var sectionNames = this.state.sections.map((obj) => obj.name);
    var programNames = this.state.programs.map((obj) => obj.name);
    var userGroupNames = this.state.userGroups.map((obj) => obj.name);
    var statusOptions = ["ACTIVE", "INACTIVE"];
    var removeExistingUserGroups = ["Y", "N"];

    var jsonAllStudentFileData = [];
    var reader = new FileReader();
    reader.onload = function (file) {
      var content = file.target.result;
      var rows = file.target.result.split(/[\r\n|\n]+/);

      //i=0 is for Header row, with keys need to send
      var keysArray = rows[0].split(",");
      for (var i = 1; i < rows.length - 1; i++) {
        var valueArr = rows[i].split(",");
        if (valueArr[0].trim() != "") {
          var jsonStudentData = {};
          for (var j = 0; j < keysArray.length; j++) {
            if (valueArr[j].trim() != "") {
              if (
                keysArray[j].trim() == "branch" &&
                !branchNames.includes(valueArr[j].trim())
              ) {
                this.setState({ uploadStatus: "Failure" });
                this.setState({
                  errorMessage:
                    "Unregistered " +
                    keysArray[j].trim() +
                    " " +
                    valueArr[j].trim() +
                    " provided for user " +
                    valueArr[0].trim(),
                });
                return;
              }
              if (
                keysArray[j].trim() == "section" &&
                !sectionNames.includes(valueArr[j].trim())
              ) {
                this.setState({ uploadStatus: "Failure" });
                this.setState({
                  errorMessage:
                    "Unregistered " +
                    keysArray[j].trim() +
                    " " +
                    valueArr[j].trim() +
                    " provided for user " +
                    valueArr[0].trim(),
                });
                return;
              }
              if (
                keysArray[j].trim() == "program" &&
                !programNames.includes(valueArr[j].trim())
              ) {
                this.setState({ uploadStatus: "Failure" });
                this.setState({
                  errorMessage:
                    "Unregistered " +
                    keysArray[j].trim() +
                    " " +
                    valueArr[j].trim() +
                    " provided for user " +
                    valueArr[0].trim(),
                });
                return;
              }
              if (
                keysArray[j].trim() == "usergroup" &&
                !userGroupNames.includes(valueArr[j].trim())
              ) {
                this.setState({ uploadStatus: "Failure" });
                this.setState({
                  errorMessage:
                    "Unregistered " +
                    keysArray[j].trim() +
                    " " +
                    valueArr[j].trim() +
                    " provided for user " +
                    valueArr[0].trim(),
                });
                return;
              }
              if (
                keysArray[j].trim() == "status" &&
                !statusOptions.includes(valueArr[j].trim())
              ) {
                this.setState({ uploadStatus: "Failure" });
                this.setState({
                  errorMessage:
                    "Incorrect value " +
                    keysArray[j].trim() +
                    " " +
                    valueArr[j].trim() +
                    " provided for user " +
                    valueArr[0].trim() +
                    ". Possible values are ACTIVE/INACTIVE",
                });
                return;
              }
              if (
                keysArray[j].trim() == "remove_existing_usergroups" &&
                !removeExistingUserGroups.includes(valueArr[j].trim())
              ) {
                this.setState({ uploadStatus: "Failure" });
                this.setState({
                  errorMessage:
                    "Incorrect value " +
                    keysArray[j].trim() +
                    " " +
                    valueArr[j].trim() +
                    " provided for user " +
                    valueArr[0].trim() +
                    ". Possible values are Y/N",
                });
                return;
              }
              jsonStudentData[keysArray[j].trim().toLowerCase()] =
                valueArr[j].trim();
            } else {
              //valueArr[0] assuming first column in the name
              this.setState({ uploadStatus: "Failure" });
              this.setState({
                errorMessage:
                  "The value " +
                  keysArray[j].trim() +
                  " is not provided for user " +
                  valueArr[0].trim(),
              });
              return;
            }
          }
          jsonAllStudentFileData.push(jsonStudentData);
        }
      }

      var emailsList = jsonAllStudentFileData.map((student) => student.email);
      var usernameList = jsonAllStudentFileData.map(
        (student) => student.username
      );

      var duplicateEmailsList = this.findDuplicates(emailsList);
      if (duplicateEmailsList && duplicateEmailsList.length > 0) {
        this.setState({ uploadStatus: "Failure" });
        this.setState({
          errorMessage: "Duplicate Email(s) found for " + duplicateEmailsList,
        });
        return;
      }

      var duplicateUsernameList = this.findDuplicates(usernameList);
      if (duplicateUsernameList && duplicateUsernameList.length > 0) {
        this.setState({ uploadStatus: "Failure" });
        this.setState({
          errorMessage:
            "Duplicate Username(s) found for " + duplicateUsernameList,
        });
        return;
      }

      this.postJSONFileData(jsonAllStudentFileData);
    }.bind(this);
    //Only File is processed
    reader.readAsText(this.state.files[0]);
  };

  findDuplicates(array) {
    return array.filter((item, index) => array.indexOf(item) !== index);
  }

  postJSONFileData(jsonAllStudentFileData) {
    $.ajax({
      type: "POST",
      url: getBaseURL() + "/registration/testparticipant/uploadFileData",
      data: JSON.stringify(jsonAllStudentFileData),
      contentType: "application/json; charset=utf-8",
      //headers: JSON.parse(localStorage.user)
    })
      .done((response, status, jqXHR) => {
        console.log("File processed successfully");
        this.setState({ uploadStatus: "Success" });
        this.setState({
          errorMessage:
            jqXHR["responseJSON"] && jqXHR["responseJSON"].message
              ? jqXHR["responseJSON"].message
              : "More information could not be retrieved.",
        });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        this.setState({ uploadStatus: "Failure" });
        this.setState({
          errorMessage:
            jqXHR["responseJSON"] && jqXHR["responseJSON"].message
              ? jqXHR["responseJSON"].message
              : "Error Message could not be retrieved.",
        });
      });
  }

  downloadTemplate = (e) => {
    window.open("/registration_template/AssessCoreRegistration.csv", "_self");
  };

  render() {
    const { classes } = this.props;
    const { uploadStatus } = this.state;
    const titleStyle = {
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "20px",
      paddingRight: "5.5%",
      textAlign: "center",
    };
    const subtitleStyle = {
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      paddingBottom: "10px",
    };
    const tableTitleStyle = {
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      padding: "0px",
      margin: "0px",
      paddingTop: "10px",
    };

    return (
      <React.Fragment>
        <GridContainer
          style={{
            backgroundColor: "white",
            paddingTop: "0px",
            paddingBottom: "20px",
            width: "100%",
            margin: "10px 0px 0px",
            marginTop: "0px",
          }}
          spacing={24}
        >
          <GridItem
            xs={12}
            sm={12}
            md={12}
            style={{ paddingTop: "10px", paddingBottom: "0px" }}
          >
            <h1 style={titleStyle}>Students Registration</h1>
            <hr />
          </GridItem>
          <AppBar position="static" className={classes.tabPanel} elevation={0}>
            <Tabs
              centered
              value={this.state.value}
              onChange={this.handleTabChange}
            >
              <Tab label="Register New User" />
              {/* <Tab label="Bulk Registration"/> */}
            </Tabs>
          </AppBar>
          {this.state.value === 0 && (
            <RegisterNewUser
              programs={this.state.programs}
              branches={this.state.branches}
              sections={this.state.sections}
              userRoles={this.state.userRoles}
              userGroups={this.state.userGroups}
              history={this.props.history}
            />
          )}
          {this.state.value === 1 && (
            <React.Fragment>
              <GridContainer
                style={{
                  backgroundColor: "white",
                  paddingTop: "0px",
                  // paddingBottom: "300px",
                  width: "100%",
                  margin: "10px 0px 0px",
                  marginTop: "20px",
                }}
                spacing={24}
              >
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: "12px" }}
                ></GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: "12px", paddingBottom: "24px" }}
                >
                  <h4 style={tableTitleStyle}>
                    Multiple Students Registration
                  </h4>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: "12px" }}
                ></GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: "12px" }}
                ></GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: "20px", paddingTop: "0px" }}
                >
                  <div style={{ paddingBottom: "10px" }}>
                    <span className={classes.robotoFontRegularLabel}>
                      Upload student data file (.csv)
                    </span>
                  </div>
                  <FilePond
                    files={this.state.files}
                    onupdatefiles={this.setFiles}
                  />
                  <div
                    style={{
                      margin: "16px 0px 16px auto",
                      width: "fit-content",
                    }}
                  >
                    <Link onClick={this.downloadTemplate}>
                      Download Excel Template
                    </Link>
                  </div>
                  <Button
                    style={{
                      textTransform: "none",
                      backgroundColor: "#3f51b5",
                      padding: "12px 20px",
                      margin: "5px 0px",
                      width: "120px",
                    }}
                    onClick={this.uploadFileData}
                  >
                    Upload file
                  </Button>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: "12px" }}
                ></GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: "12px" }}
                ></GridItem>
                {uploadStatus && (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{
                      padding: "20px",
                      paddingTop: "0px",
                      paddingBottom: "10px",
                    }}
                  >
                    <p
                      className={classes.robotoFontRegular}
                      style={
                        uploadStatus == "Failure"
                          ? { color: "red" }
                          : uploadStatus == "Success"
                          ? { color: "green" }
                          : {}
                      }
                    >
                      Upload Status: {uploadStatus}
                    </p>
                    <p
                      style={{ whiteSpace: "normal" }}
                      className={classes.robotoFontRegularLabel}
                    >
                      {this.state.errorMessage}
                    </p>
                  </GridItem>
                )}
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  style={{ padding: "12px" }}
                ></GridItem>
              </GridContainer>
            </React.Fragment>
          )}
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CreateNewUser);
