import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Button from "CustomButtons/Button.jsx";


const getMuiThemeForTable =  createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: "none"
        }
      },
      MUIDataTableToolbarSelect:{
        title:{
          display: "none"
        },
        root:{
          backgroundColor: "none",
          boxShadow: "none",
          justifyContent: "normal"
        }
      },
      MuiTableCell:{
        root:{
          padding: "4px 4px 4px 4px",
          textAlign: "center",
        },
        body:{
           fontSize: "12px"
        }
      },
      MUIDataTableHeadCell: {
        root:{
          color: 'rgba(0,0,0,0.54)',
          fontSize: '0.75rem',
          fontWeight: '500',
          lineHeight: '24px',
          height: '56px'
        },
        fixedHeader:{
          zIndex: "none"        

        },
        toolButton:{
          width: "fit-content",
          margin: "auto"
        },
        contentWrapper:{
          justifyContent: "center"
        }
      },
      MuiTablePagination:{
        root:{
          borderBottom: '0px'
        },
        caption:{
          color:'rgba(0,0,0,0.54)',
          fontSize: '0.75rem'
        },
        select:{
          color:'rgba(0,0,0,0.54)',
          fontSize: '0.75rem'
        }
      },
    typography: {
      useNextVariants: true,
    },
    },
    typography: {
      useNextVariants: true,
    }
  })


class MarksAndRanksList extends React.Component {

  state = {
    testResults:[],
    testResultsTableColumns: [],
    downloadFile: true
  }

  componentDidMount(){
    this.populateTestResultDataTable(this.props.data);
  }

  componentDidUpdate(prevProps){
    if(this.props != prevProps ){
      this.populateTestResultDataTable(this.props.data);
    }
  }


  populateTestResultDataTable(data){
    var testResults=[];
    var testResultsTableColumns = []
    var results = data["results"];
    if(!results || !results.length > 0){
      //TODO error message handling
      this.setState({testResults});
      return
    }
    if( results && results.length > 0){
      const subjectString = results[0].sub_names;
      if(!subjectString){
        //TODO error message handling
        this.setState({testResults});
        return
      }
      const subjectsArray = subjectString.split(',');
      if(subjectsArray.length < 1){
        //TODO error message handling
        this.setState({testResults});
        return
      }
      //Push actual results data to table
      for(var i=0; i < results.length ; i++){
        var resultRow = results[i]; 
        var testResultRow = []
        testResultRow.push(resultRow["global_user_id"]);
        testResultRow.push(resultRow["user_login_id"]);
        testResultRow.push(resultRow["user_name"]);
        testResultRow.push(resultRow["branch_name"]);
        testResultRow.push(resultRow["section_name"]);
        for(var j=0; j < subjectsArray.length ; j++){
          testResultRow.push(parseFloat(resultRow["subjects_info"][subjectsArray[j]]["marks"]));
          testResultRow.push(parseFloat(resultRow["subjects_info"][subjectsArray[j]]["rank"]));
        }
        testResultRow.push(resultRow["total_marks"]);
        testResultRow.push(resultRow["rank"]);
        testResults.push(testResultRow);
      }

      //Push column names of table
      testResultsTableColumns.push(
        {name: "userId",
            options: {
              display: false,
            }
        }
      ); 
      testResultsTableColumns.push(
        {name: "username",
            options: {
              display: false,
            }
        }
      );  
      testResultsTableColumns.push(
        {
          name: "Name",
          options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              const linkButtonStyle = {
                textTransform: "none",
                backgroundColor: "white",
                color: "#3f51b5",
                borderRadius: "3px",
                padding: "0.3rem 0.75rem",
                boxShadow: "none",
                fontSize: "12px"
              }
              return (
              <div>
                  <Button
                    onClick={this.props.goToUserTestAnalysis}
                    name={tableMeta.rowData[0]}
                    style={linkButtonStyle}
                    size="sm"
                    >
                     {value}
                  </Button>
              </div>
              );
              }
          }
        }
      );
      testResultsTableColumns.push({name: "Campus"});
      testResultsTableColumns.push({name: "Section"});
      for(var j=0; j < subjectsArray.length ; j++){
          testResultsTableColumns.push({name: subjectsArray[j] + " Marks"});
          testResultsTableColumns.push({name: subjectsArray[j] + " Rank"});
        }
      testResultsTableColumns.push({name: "Total Marks"});
      testResultsTableColumns.push({name: "Total Rank"});

      this.setState({testResultsTableColumns,testResults});
    }
  }

  render () {
    const {reportName} = this.props;
    const tableTitleStyle={
      color: "rgb(34, 56, 69)",
      fontWeight: "600",
      fontSize: "18px",
      fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
      padding: "0px", margin: "0px",
      paddingTop: "10px"
    };
    const testResultsTableOptions = {
      filter: false,
      selectableRows: 'none',
      search: true,
      print: false,
      download: false,
      viewColumns: false,
      responsive: 'stacked',
      expandableRows: false,
      downloadOptions: {
        filename: reportName ? reportName+ "_TestPerformance_"+ (new Date().toISOString().substring(0,16))+".csv" : "TestPerformance_"+ (new Date().toISOString().substring(0,16))+".csv",
        separator: ',',
      },
      download: true,
      onDownload: (buildHead, buildBody, columns, data) => 
        buildHead(columns) + buildBody(data),
    }
    return (
      <React.Fragment>
          <MuiThemeProvider theme={getMuiThemeForTable}>
            <MUIDataTable 
              title={<p style={tableTitleStyle} >{reportName + " - Test Performance"}</p>}
              options={testResultsTableOptions}
              columns={this.state.testResultsTableColumns}  
              data={this.state.testResults}              
            />
          </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default MarksAndRanksList;
