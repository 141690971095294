import React from "react";
import ReactDOM from "react-dom";
import QuestionHeader from "components/test/view/QuestionHeader.jsx";
import QuestionText from "components/test/view/QuestionText.jsx";
import QuestionImages from "components/test/view/QuestionImages.jsx";
import SingleAnswerOptions from "components/test/view/SingleAnswerOptions.jsx";
import MultipleAnswerOptions from "components/test/view/MultipleAnswerOptions.jsx";
import NumericalAnswerComponent from "components/test/view/NumericalAnswerComponent.jsx";
import QuestionFooter from "components/test/view/QuestionFooter.jsx";

import TestInstructionsPreview from "components/test/view/TestInstructionsPreview.js";
import Button from "CustomButtons/Button.jsx";

import QuestionTextInput from "components/test/view/QuestionTextInput.jsx";
import SingleAnswerQuestionChoices from "components/lov/SingleAnswerQuestionChoices";
import MultipleAnswerQuestionChoices from "components/lov/MultipleAnswerQuestionChoices";
import MatrixGridListsComponent from "components/test/view/MatrixGridListsComponent.jsx";
import MatrixGridAnswerComponent from "components/test/view/MatrixGridAnswerComponent.jsx";
import TablePagination from "@material-ui/core/TablePagination";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import { formatDate } from "components/helpers/DateTimeHelper.jsx";

const modalStyles = {
  ...modalStyle,
};

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

function PopupDialog(props) {
  const { classes, fetchHistory, open, handleClose } = props;
  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle disableTypography className={classes.modalHeader}>
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <h4>Fetch History</h4>
      </DialogTitle>
      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        <div>
          {fetchHistory?.map((x) => (
            <h5
              style={{
                fontFamily: "serif",
              }}
            >
              {formatDate(x?.fetchedAt?.$date) +
                " | " +
                x?.fetchClientReference}
            </h5>
          ))}
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={handleClose}
          style={{
            backgroundColor: "#3f51b5",
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

class TestQuestionsContainerListView extends React.Component {
  state = {
    testDataAllChuncks: [],
    showSolution: false,
    displaySolutionForQuestionNumber: "",
    showAllSolutions: false,
    fetchHistoryModal: false,
    fetchHistoryForModal: [],
  };

  componentDidMount() {
    if (window.MathJax) {
      window.MathJax.Hub.Queue([
        "Typeset",
        window.MathJax.Hub,
        ReactDOM.findDOMNode(this),
      ]);
    }
    this.handleTestDataChunking();
  }

  componentDidUpdate(prevProps) {
    if (window.MathJax) {
      window.MathJax.Hub.Queue([
        "Typeset",
        window.MathJax.Hub,
        ReactDOM.findDOMNode(this),
      ]);
    }
    if (this.props.completeTestData != prevProps.completeTestData) {
      this.handleTestDataChunking();
    }
  }

  handleTestDataChunking() {
    var completeTestData = this.props.completeTestData;
    var testDataAllChuncks = [];
    if (
      completeTestData &&
      completeTestData["questions"] &&
      completeTestData["questions"].length > 0
    ) {
      var i,
        j,
        testDataChuncksArray,
        chunkSize = 30;
      for (
        i = 0, j = completeTestData["questions"].length;
        i < j;
        i += chunkSize
      ) {
        testDataChuncksArray = completeTestData["questions"].slice(
          i,
          i + chunkSize
        );
        testDataAllChuncks.push(testDataChuncksArray);
      }
      this.setState({ testDataAllChuncks });
    }
  }

  getInlineComponentStyles(data, index) {
    if (!data || data.length < 1) {
      return false;
    }
    if (data[index][1].includes("inline")) {
      return true;
    }
    if (index > 0 && data[index - 1][1].includes("inline")) {
      return true;
    }
    return false;
  }

  toggleShowSolution = (e) => {
    var showSolution = this.state.showSolution;
    var name = e.currentTarget.name;
    var displaySolutionForQuestionNumber = name.split("_")[1];
    if (!this.state.displaySolutionForQuestionNumber) {
      this.setState({
        showSolution: !showSolution,
        displaySolutionForQuestionNumber,
      });
    } else if (
      displaySolutionForQuestionNumber !=
      this.state.displaySolutionForQuestionNumber
    ) {
      this.setState({ displaySolutionForQuestionNumber });
    } else {
      this.setState({ showSolution: !showSolution });
    }
  };

  tryParseJSON(jsonString) {
    try {
      var o = JSON.parse(jsonString);
      if (o && typeof o === "object") {
        return o;
      }
    } catch (e) {}

    return jsonString;
  }

  enableKeyAndSolutions = (e) => {
    this.setState({ showAllSolutions: !this.state.showAllSolutions });
  };

  render() {
    const {
      classes,
      completeTestData,
      handleTestPrint,
      navTestData,
      isFetchQuestionsView,
      handleFetchQuestion,
      renderShowSolutionButton,
      title,
      handleReportQuestion,
      handleEditQuestion,
      paginationEnabledFlag,
      handlePagination,
      paginationParams,
      fetchedQuestionsSourceIds,
    } = this.props;
    const {
      testDataAllChuncks,
      showSolution,
      displaySolutionForQuestionNumber,
      showAllSolutions,
    } = this.state;
    const buttonStyle = {
      float: "right",
      color: "white",
      position: "relative",
      padding: "0.9375rem",
      //fontWeight: "600",
      fontSize: "12px",
      textTransform: "capitalize",
      borderRadius: "3px",
      lineHeight: "20px",
      textDecoration: "none",
      margin: "0px",
      marginLeft: "5px",
      marginRight: "5px",
      background: "#3f51b5",
      display: "inline-flex",
      paddingTop: "6px",
      paddingBottom: "6px",
      paddingLeft: "15px",
      paddingRight: "15px",
      "&:hover,&:focus": {
        color: "white",
        background: "#57849b",
      },
    };
    const style3 = {
      fontFamily: "sans-serif",
      paddingTop: "0px",
      paddingBottom: "10px",
      textAlign: "center",
      margin: "auto",
      color: "rgb(60, 72, 88)",
    };
    return (
      <div style={{ paddingBottom: "50px" }}>
        {" "}
        {/*FOR THE PAGE TO BE SCROLLABLE WITHOUT FOOTER OCCLUSION*/}
        <div style={isFetchQuestionsView ? {} : { padding: "20px" }}>
          {!isFetchQuestionsView && handleTestPrint && (
            <React.Fragment>
              <Button
                color="transparent"
                name="edit"
                onClick={handleTestPrint}
                style={buttonStyle}
                //className={classes.navLink}
                size="sm"
              >
                Print
              </Button>
              <Button
                color="transparent"
                name="edit"
                onClick={this.enableKeyAndSolutions}
                style={buttonStyle}
                //className={classes.navLink}
                size="sm"
              >
                {showAllSolutions
                  ? "Hide Key & Solutions"
                  : "Show Key & Solution"}
              </Button>
            </React.Fragment>
          )}
        </div>
        <div id="windowPrint">
          {navTestData &&
            navTestData.length > 0 &&
            completeTestData &&
            completeTestData["test_details"] && (
              <TestInstructionsPreview
                testInfoData={completeTestData["test_details"]}
                testInstructionsData={navTestData}
                {...this.props}
              />
            )}
          {testDataAllChuncks && testDataAllChuncks.length > 0
            ? testDataAllChuncks.map((testDataChunck, index) => (
                <div key={index} id="divToPrint">
                  {index == 0 && !isFetchQuestionsView ? (
                    <h3 style={style3}>{title ? title : "Test Questions"}</h3>
                  ) : (
                    ""
                  )}
                  {paginationEnabledFlag && (
                    <div style={{ marginBottom: "-20px" }}>
                      <TablePagination
                        component="nav"
                        page={paginationParams["page"]}
                        rowsPerPage={paginationParams["rowsPerPage"]}
                        rowsPerPageOptions={
                          paginationParams["rowsPerPageOptions"]
                        }
                        count={paginationParams["count"]}
                        onChangePage={handlePagination}
                      />
                    </div>
                  )}
                  {testDataChunck && testDataChunck.length > 0
                    ? testDataChunck.map((testData, index) => (
                        <div
                          key={index}
                          style={{ paddingLeft: "15px", paddingRight: "15px" }}
                        >
                          <hr />
                          {testData["id"] && (
                            <div>
                              <QuestionText
                                text={
                                  testData["id"]
                                    ? "\\(QID: ASCR0" + testData["id"] + "\\)"
                                    : ""
                                }
                              />
                              {testData["used_in_past"] && (
                                <p
                                  style={{
                                    display: "inline",
                                    color: "orangered",
                                    fontFamily: "serif",
                                  }}
                                >
                                  {" "}
                                  Fetched In Past
                                </p>
                              )}
                            </div>
                          )}
                          {fetchedQuestionsSourceIds?.includes(
                            testData?._id?.$oid
                          ) ? (
                            <p
                              style={{
                                display: "inline",
                                color: "orangered",
                                fontFamily: "serif",
                              }}
                            >
                              {"Already fetched to this test"}
                            </p>
                          ) : (
                            testData?.fetchHistory &&
                            testData?.fetchHistory?.length > 0 && (
                              <p
                                style={{
                                  display: "inline",
                                  color: "orangered",
                                  fontFamily: "serif",
                                }}
                              >
                                {"Last Fetched: " +
                                  formatDate(
                                    testData?.fetchHistory[
                                      testData?.fetchHistory?.length - 1
                                    ]?.fetchedAt?.$date
                                  ) +
                                  " | " +
                                  testData?.fetchHistory[
                                    testData?.fetchHistory?.length - 1
                                  ]?.fetchClientReference}
                              </p>
                            )
                          )}

                          {testData["question_content"] &&
                          testData["question_content"].passage &&
                          testData["question_content"].passage.length > 0 ? (
                            <div>
                              {testData["question_content"].passage.map(
                                (item, index) => (
                                  <React.Fragment
                                    key={index}
                                    /*style={
											  this.getInlineComponentStyles((testData["question_content"]["question"]),index)
									          ? {display: "inline-block"} : {} }*/
                                  >
                                    {item[0] ? (
                                      <QuestionText
                                        text={
                                          index == 0
                                            ? "Passage: " + item[0]
                                            : item[0]
                                        }
                                      />
                                    ) : null}
                                    {item[1] ? (
                                      <QuestionImages
                                        src={item[1]}
                                        name={"passage_" + index + "_1"}
                                        isEditMode={false}
                                      />
                                    ) : null}
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          ) : null}
                          {testData["question_content"] &&
                          testData["question_content"].question &&
                          testData["question_content"].question.length > 0 ? (
                            <div>
                              {testData["question_content"].question.map(
                                (item, index) => (
                                  <React.Fragment
                                    key={index}
                                    /*style={
											  this.getInlineComponentStyles((testData["question_content"]["question"]),index)
									          ? {display: "inline-block"} : {} }*/
                                  >
                                    {item[0] ? (
                                      <QuestionText
                                        text={
                                          index == 0
                                            ? "Q" +
                                              testData["question_no"] +
                                              ") " +
                                              item[0]
                                            : item[0]
                                        }
                                      />
                                    ) : null}
                                    {item[1] ? (
                                      <QuestionImages
                                        src={item[1]}
                                        name={"question_" + index + "_1"}
                                        isEditMode={false}
                                      />
                                    ) : null}
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          ) : null}
                          {testData["question_content"] &&
                            (testData["pattern_short_name"] == "MM" ||
                              (testData["pattern_short_name"] == "MCQ" &&
                                testData["question_content"].list1 &&
                                testData["question_content"].list2 &&
                                testData["question_content"].list1.length > 0 &&
                                testData["question_content"].list2.length >
                                  0)) && (
                              <div
                                style={{
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <MatrixGridListsComponent
                                  {...this.props}
                                  list1={testData["question_content"].list1}
                                  list2={testData["question_content"].list2}
                                  isEditMode={false}
                                />
                              </div>
                            )}
                          {testData["question_content"] &&
                          testData["question_content"].options &&
                          testData["question_content"].options.length > 0 &&
                          (testData["pattern_short_name"] == "MCQ" ||
                            testData["pattern_short_name"] == "MAQ" ||
                            testData["pattern_short_name"] == "PASSAGE") ? (
                            <div style={{ paddingTop: "15px" }}>
                              {testData["question_content"].options.map(
                                (item, index) => (
                                  //Add div component for option to move to independent line each
                                  <div>
                                    {item.content &&
                                      item.content.map(
                                        (itemContent, index2) => (
                                          <React.Fragment key={index2}>
                                            {index2 % 2 == 0 && (
                                              <QuestionText
                                                text={
                                                  index2 == 0
                                                    ? item.label +
                                                      ") " +
                                                      itemContent
                                                    : itemContent
                                                }
                                              />
                                            )}
                                            {index2 % 2 == 1 && itemContent && (
                                              <QuestionImages
                                                src={itemContent}
                                                name={
                                                  "option_" +
                                                  item.label +
                                                  "_" +
                                                  index2
                                                }
                                                isEditMode={false}
                                              />
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </div>
                                )
                              )}
                            </div>
                          ) : null}
                          {(showAllSolutions ||
                            (displaySolutionForQuestionNumber ==
                              testData["question_no"] &&
                              showSolution)) &&
                          testData["key"] &&
                          (testData["pattern_short_name"] == "MCQ" ||
                            testData["pattern_short_name"] == "PASSAGE") ? (
                            <div style={{ paddingTop: "15px" }}>
                              <h4
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "600",
                                  fontSize: "18px",
                                }}
                              >
                                Key
                              </h4>
                              {this.tryParseJSON(testData["key"]).map(
                                (item, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      marginLeft: "20px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <SingleAnswerQuestionChoices
                                      name={"key_" + index + "_0"}
                                      formControlWidth="20%"
                                      isDisabled={true}
                                      value={item[0] ? item[0] : ""}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          ) : null}
                          {(showAllSolutions ||
                            (displaySolutionForQuestionNumber ==
                              testData["question_no"] &&
                              showSolution)) &&
                          testData["key"] &&
                          testData["pattern_short_name"] == "MAQ" ? (
                            <div style={{ paddingTop: "15px" }}>
                              <h4
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "600",
                                  fontSize: "18px",
                                }}
                              >
                                Key
                              </h4>
                              {this.tryParseJSON(testData["key"]).map(
                                (item, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      marginLeft: "20px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <MultipleAnswerQuestionChoices
                                      name={"key_" + index + "_0"}
                                      formControlWidth="20%"
                                      isDisabled={true}
                                      value={item ? item : []}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          ) : null}
                          {(showAllSolutions ||
                            (displaySolutionForQuestionNumber ==
                              testData["question_no"] &&
                              showSolution)) &&
                          testData["key"] &&
                          (testData["pattern_short_name"] == "NUMERICAL" ||
                            testData["pattern_short_name"] == "INTEGER") ? (
                            <div style={{ paddingTop: "15px" }}>
                              <h4
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "600",
                                  fontSize: "18px",
                                }}
                              >
                                Key
                              </h4>
                              {this.tryParseJSON(testData["key"]).map(
                                (item, index) => (
                                  <div key={index} style={{ width: "25%" }}>
                                    <QuestionText
                                      text={
                                        item[0]
                                          ? "Numerical Answer: " + item[0]
                                          : "Not Provided"
                                      }
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          ) : null}
                          {(showAllSolutions ||
                            (displaySolutionForQuestionNumber ==
                              testData["question_no"] &&
                              showSolution)) &&
                          testData["question_content"] &&
                          testData["key"] &&
                          testData["pattern_short_name"] == "MM" ? (
                            <MatrixGridAnswerComponent
                              {...this.props}
                              answer={""}
                              showSolutions={true}
                              AnswerKey={testData.key}
                              list1={testData["question_content"].list1}
                              list2={testData["question_content"].list2}
                            />
                          ) : null}
                          {(showAllSolutions ||
                            (displaySolutionForQuestionNumber ==
                              testData["question_no"] &&
                              showSolution)) &&
                          testData["solution"] ? (
                            <div style={{ paddingTop: "15px" }}>
                              <h4
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "600",
                                  fontSize: "18px",
                                }}
                              >
                                Solution
                              </h4>
                              {JSON.parse(testData["solution"]).map(
                                (item, index) => (
                                  <React.Fragment
                                    key={index}
                                    /*style={
										  this.getInlineComponentStyles(JSON.parse(testData["solution"]),index)
								          ? {} : {} }*/
                                  >
                                    {item[0] ? (
                                      <QuestionText text={item[0]} />
                                    ) : null}
                                    {item[1] ? (
                                      <QuestionImages
                                        src={item[1]}
                                        name={"solution_" + index + "_1"}
                                        isEditMode={false}
                                        backgroundColor="transparent"
                                      />
                                    ) : null}
                                  </React.Fragment>
                                )
                              )}
                            </div>
                          ) : null}
                          {isFetchQuestionsView && (
                            <div style={{ padding: "20px", paddingTop: "0px" }}>
                              <Button
                                name={"fetch_" + testData["question_no"]}
                                color="transparent"
                                onClick={handleFetchQuestion}
                                style={
                                  fetchedQuestionsSourceIds.includes(
                                    testData?._id?.$oid
                                  )
                                    ? { ...buttonStyle, backgroundColor: "red" }
                                    : {
                                        ...buttonStyle,
                                        backgroundColor: "#3f51b5",
                                      }
                                }
                                //className={classes.navLink}
                                size="sm"
                              >
                                Fetch
                              </Button>
                              {testData?.fetchHistory &&
                                testData?.fetchHistory?.length > 0 && (
                                  <>
                                    <Button
                                      name={
                                        "fetch_hist_" + testData["question_no"]
                                      }
                                      color="transparent"
                                      onClick={() =>
                                        this.setState({
                                          fetchHistoryModal: true,
                                          fetchHistoryForModal:
                                            testData?.fetchHistory,
                                        })
                                      }
                                      style={{
                                        ...buttonStyle,
                                        color: "black",
                                        backgroundColor: "#eeeeee",
                                      }}
                                      //className={classes.navLink}
                                      size="sm"
                                    >
                                      History
                                    </Button>
                                  </>
                                )}
                            </div>
                          )}
                          {renderShowSolutionButton && (
                            <div style={{ padding: "20px", paddingTop: "0px" }}>
                              <Button
                                name={"showSolution_" + testData["question_no"]}
                                color="transparent"
                                onClick={this.toggleShowSolution}
                                style={buttonStyle}
                                //className={classes.navLink}
                                size="sm"
                              >
                                {showAllSolutions ||
                                (displaySolutionForQuestionNumber ==
                                  testData["question_no"] &&
                                  showSolution)
                                  ? "Hide Solution"
                                  : "Show Solution"}
                              </Button>
                              <Button
                                name={"reportQuestion_" + testData["id"]}
                                color="transparent"
                                onClick={handleReportQuestion}
                                style={buttonStyle}
                                //className={classes.navLink}
                                size="sm"
                              >
                                {" "}
                                Report Question
                              </Button>
                              <Button
                                name={"editQuestion_" + testData["id"]}
                                color="transparent"
                                onClick={handleEditQuestion}
                                style={buttonStyle}
                                //className={classes.navLink}
                                size="sm"
                              >
                                {" "}
                                Edit Question
                              </Button>
                            </div>
                          )}
                        </div>
                      ))
                    : null}
                  {paginationEnabledFlag && (
                    <div style={{ paddingTop: "30px" }}>
                      <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                      <TablePagination
                        component="nav"
                        page={paginationParams["page"]}
                        rowsPerPage={paginationParams["rowsPerPage"]}
                        rowsPerPageOptions={
                          paginationParams["rowsPerPageOptions"]
                        }
                        count={paginationParams["count"]}
                        onChangePage={handlePagination}
                      />
                    </div>
                  )}
                </div>
              ))
            : null}
        </div>
        <PopupDialog
          open={this.state.fetchHistoryModal}
          handleClose={() =>
            this.setState({
              fetchHistoryModal: false,
              fetchHistoryForModal: [],
            })
          }
          fetchHistory={this.state.fetchHistoryForModal}
          {...this.props}
        />
      </div>
    );
  }
}

export default withStyles(modalStyles)(TestQuestionsContainerListView);
