import React from "react";
import $ from 'jquery';
import qs from 'query-string';

import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";

import Button from "CustomButtons/Button.jsx";

import LinearProgress from '@material-ui/core/LinearProgress';

import Info from '@material-ui/icons/Info';
import List from '@material-ui/icons/List';

//import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

import CustomInput from "CustomInput/CustomInput.jsx";


import { container, title } from "assets/jss/material-kit-react.jsx";

import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import tooltipsStyle from "assets/jss/material-kit-react/tooltipsStyle.jsx";
import popoverStyles from "assets/jss/material-kit-react/popoverStyles.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

import brand from "assets/img/images/brand_black.png";
import Grid from "@material-ui/core/Grid";

import Menu from '@material-ui/core/Menu';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const javascriptStyles = {
  section: {
    padding: "70px 0 0"
  },
  ...container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  icon: {
    width: "17px",
    height: "17px",
    marginRight: "4px"
  },
  ...modalStyle,
  label: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    transition: "0.3s ease all",
    lineHeight: "1.428571429",
    fontWeight: "400",
    paddingLeft: "0"
  },
  ...tooltipsStyle,
  ...popoverStyles,
  ...imagesStyle
};


//////////////////////////////////


function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const style2={
	fontWeight: "400",
	fontFamily: "serif-sans",
	fontSize: 24
	};

 class TestHeader extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
		    value: 0,
		    modal: false,
		    test_id: '',
		    test_paper_id: '',
		    password: "",
		    fetchQuestionsMenuOpen: false,
		};
		this.fetchQuestionsMenuAnchorRef = React.createRef();
	}

		componentDidMount() {
			const test_paper_id = qs.parse(this.props.location.search).test_paper_id;
			const test_id = qs.parse(this.props.location.search).test_id;
			if(test_paper_id){
				 this.setState({test_paper_id: test_paper_id});
			}
			if(test_id){
				 this.setState({test_id: test_id});
			}
	  	}	


	   handleGoBack = (e) => {
	    this.props.history.push('/manage-tests');
	  };

	//shouldComponentUpdate(nextProps) {
    //	return (this.props !== nextProps);
	//}

 	  handleFetchQuestionsMenuItemClick = (event, source) => {
 	    this.setFetchQuestionsMenuOpen(false);
 	  	if(source){
 	    	this.props.toggleFetchQuestionsView(event, source);
 	    }
 	  }

 	   handleFetchQuestionsMenuToggle = (e) => {	
 	     this.setFetchQuestionsMenuOpen(!this.state.fetchQuestionsMenuOpen);
 	  }

 	   handleFetchQuestionsMenuClose = (event) => {
 	    if(this.fetchQuestionsMenuAnchorRef.current && this.fetchQuestionsMenuAnchorRef.current.contains(event.target)) {
 	      return;
 	    }
 	    this.setFetchQuestionsMenuOpen(false);
 	  }

 	  setFetchQuestionsMenuOpen(fetchQuestionsMenuOpen) {
 	  	this.setState({fetchQuestionsMenuOpen})
 	  }

render() {
	const { classes,testInfoData,handleSubmitTest,handleReviewComplete, testViewMode,
			handleTestViewModeChange,toggleFetchQuestionsView, isFetchQuestionsView, isApprover} = this.props;
	const {fetchQuestionsMenuOpen} = this.state;
	const style={
	paddingTop: "0px", 
	paddingBottom: "0px"
	};
	const style2={
		//color: "rgba(0, 0, 0, 0.87)",
	//fontWeight: "bold",
		fontFamily: "serif",
		fontSize: "14px",
		color: "rgb(60, 72, 88)",
		whiteSpace: "nowrap", 
    	overflow: "hidden", 
    	textOverflow: "ellipsis",
    	maxWidth: "170px",
    	padding: '12px',
		//color: "rgb(60, 72, 88)",
		//lineHeight: "1.55em",
		//marginTop: "10px"
	};
	const style3={
		color: "rgb(103, 106, 108)",
		fontWeight: "300"
		//color: "rgba(0, 0, 0, 0.87)",
	//fontWeight: "bold",
		//fontFamily: "serif",
		//fontSize: "14px"
	};
	const buttonStyle = {
		paddingTop: "8px",
		paddingBottom: "8px",
		paddingLeft: "20px",
		paddingRight: "20px",
		textTransform: "capitalize",
		background: "#3f51b5",
    	lineHeight: "20px",
    	borderRadius: "3px",
    	marginLeft: "10px"
	};
	const groupedButtonStyle = {
		paddingTop: "8px",
		paddingBottom: "8px",
		paddingLeft: "20px",
		paddingRight: "10px",
		textTransform: "capitalize",
		background: "#3f51b5",
    	lineHeight: "20px",
    	borderRadius: "3px",
    	marginLeft: "10px"
	};
	return(
		<div>
			<GridContainer style={{margin: "auto", minHeight: "50px"}} alignItems="center">
				<GridItem  xs={12} sm={12} md={12} lg={2}>
					{/*<h4 style={style2}>AssessCore</h4>*/}
					<img
					  name="home"
					  src={brand}
					  alt="..."
					  style={{height: "60px"}}
					  className={
					    classes.imgRounded +
					    " " +
					    classes.imgFluid
					  }
					/>
				</GridItem>
				<GridItem  xs={12} sm={12} md={12} lg={5}>
				{ testInfoData && Object.keys(testInfoData).length > 0 &&
					<Grid container spacing={24} justify="flex-end">
						<Grid item style={style}>
							<h5 style={style2}><strong style={style3}>Test Name:</strong> {testInfoData.name}</h5>
						</Grid> 
						<Grid item style={style} >
							<h5 style={style2}><strong style={style3}>Number:</strong> {testInfoData.identifier}</h5>
						</Grid>
						<Grid item style={style} >
							<h5 style={style2}><strong style={style3}>Program:</strong> {testInfoData.program_short_name}</h5>
						</Grid>
						<Grid item style={style} >
							<h5 style={style2}><strong style={style3}>Type:</strong> {testInfoData.test_type_short_name}</h5>
						</Grid>
					</Grid>
				}
				</GridItem>
				 <GridItem  xs={12} sm={12} md={12} lg={5}>
				 { isFetchQuestionsView ? 
				 		<Button 
					 	style={buttonStyle}
					 	onClick={toggleFetchQuestionsView}>Done
					 	</Button> 
				 	:
				 	<React.Fragment>
					 	{testInfoData && Object.keys(testInfoData).length > 0  && testInfoData.status == 'INCOMPLETE' &&
						 	<Button 
						 	style={buttonStyle}
						 	onClick={handleSubmitTest}
						 	data-cy="create test submit btn">Submit Test
						 	</Button> 
					 	}
						{testInfoData && Object.keys(testInfoData).length > 0  && testInfoData.status == 'COMPLETED' && isApprover && testInfoData.review_status != "APPROVED" &&
                                                        <Button
                                                        style={buttonStyle}
                                                        onClick={handleReviewComplete}
                                                        data-cy="create test submit btn"> Approve Test
                                                        </Button>
                                                }
					 	<Button 
					 	style={buttonStyle}
					 	onClick={this.handleGoBack}>Cancel
					 	</Button> 
					 	{testViewMode == "grid" &&
					 	<Button 
					 	name="list"
					 	style={buttonStyle}
					 	onClick={handleTestViewModeChange}>List View
					 	</Button>
					 	} 
					 	{testViewMode == "list" &&
					 	<Button 
					 	name="grid"
					 	style={buttonStyle}
					 	onClick={handleTestViewModeChange}>Grid View
					 	</Button>
					 	} 
					 	{testInfoData && Object.keys(testInfoData).length > 0  && testInfoData.status == 'INCOMPLETE' && toggleFetchQuestionsView && 
					 	<React.Fragment> 
						 	<span ref={this.fetchQuestionsMenuAnchorRef}>
				 	         <Button 
				 	         	style={groupedButtonStyle}
				 	           aria-owns={fetchQuestionsMenuOpen ? 'menu-list-grow' : undefined}
				 	           aria-haspopup="true"
				 	           onClick={this.handleFetchQuestionsMenuToggle}
				 	         >	Fetch Questions 
				 	           <ArrowDropDownIcon />
				 	         </Button>
				 	         </span>
				 	       <Popper open={fetchQuestionsMenuOpen} anchorEl={this.fetchQuestionsMenuAnchorRef.current} transition disablePortal
				 	       	style={{zIndex: 1}}>
				 	         {({ TransitionProps, placement }) => (
				 	           <Grow
				 	             {...TransitionProps}
				 	             style={{
				 	               transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
				 	             }}
				 	           >
				 	             <Paper id="menu-list-grow">
				 	               <ClickAwayListener onClickAway={this.handleFetchQuestionsMenuClose}>
				 	                 <MenuList>
				 	                     <MenuItem
				 	                       name="dataset"
				 	                       style={{fontSize: "1rem", fontFamily: "serif"}}
				 	                       onClick={ e => this.handleFetchQuestionsMenuItemClick(e, "dataset")}
				 	                     >
				 	                       From Content Repository
				 	                     </MenuItem>
				 	                     <MenuItem
				 	                       name="tests"
				 	                       style={{fontSize: "1rem", fontFamily: "serif"}}
				 	                       onClick={e => this.handleFetchQuestionsMenuItemClick(e, "tests")}
				 	                     >
				 	                       From Previous Tests
				 	                     </MenuItem>
				 	                    {/* <MenuItem
				 	                       name="tests"
				 	                       style={{fontSize: "1rem", fontFamily: "serif"}}
				 	                       onClick={e => this.handleFetchQuestionsMenuItemClick(e, "metaread")}
				 	                     >
				 	                       From MetaRead Store
				 	                     </MenuItem> */}
				 	                 </MenuList>
				 	               </ClickAwayListener>
				 	             </Paper>
				 	           </Grow>
				 	         )}
				 	       </Popper>
					    </React.Fragment> 
					 	}
					</React.Fragment> 	
				 	}
				 </GridItem> 			
			</GridContainer>	
		</div>
		);
}

}


export default  withStyles(javascriptStyles)(TestHeader);
